import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "~/lib/utils";
const buttonVariants = cva("inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50", {
  variants: {
    variant: {
      // Primary bg colour Palatinate, accent Azure on hover
      default: "bg-palatinate text-smoke shadow hover:bg-azure",
      // Primary bg colour Oxford, accent Columbia on hover
      secondary: "bg-oxford text-smoke hover:bg-columbia hover:text-eerie",
      // No background, Oxford on hover
      outline: "border border-input bg-white hover:bg-oxford hover:text-white",
      ghost: "hover:text-persimmon focus-visible:ring-0",
      // Specifically for icon buttons - flexibility in case we want to chance it
      icon: "hover:text-palatinate focus-visible:ring-0",
      destructive: "bg-brick text-white hover:bg-brick/50",
      link: "text-primary underline-offset-4 hover:underline",
      tracker: "border border-input bg-white cursor-default",
      expandIcon: "group relative text-primary-foreground bg-primary hover:bg-primary/90",
      //Red button bg, persimmon on hover
      warning: "bg-brick text-smoke hover:bg-persimmon",
      //Forest button bg
      approve: "bg-forest text-smoke hover:bg-columbia hover:text-oxford",
      //Oxford blue color, brick on hover
      secondaryWarning: "bg-oxford text-smoke hover:bg-brick",
      dark: "bg-smoke text-oxford hover:bg-columbia hover:text-oxford"
    },
    size: {
      default: "h-8 p-1 px-2",
      primary: "h-8 p-1 px-2",
      secondary: "h-8 p-1 px-2",
      outline: "h-8 p-1 px-2",
      sm: "h-8 rounded-md px-3 text-xs",
      lg: "h-10 rounded-md px-8",
      icon: "h-9 w-9",
      tracker: "h-4 p-1 px-2"
    }
  },
  defaultVariants: {
    variant: "default",
    size: "default"
  }
});

// Reuse buttonVariants for buttonWithIcon
const buttonWithIconVariants = buttonVariants;
export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}
export interface ButtonWithIconProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonWithIconVariants> {
  asChild?: boolean;
  icon: React.ReactNode; // Add icon prop
}

//Button component, style variants defined above
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({
  className,
  variant,
  size,
  asChild = false,
  ...props
}, ref) => {
  const Comp = asChild ? Slot : "button";
  return <Comp className={cn(buttonVariants({
    variant,
    size,
    className
  }))} ref={ref} {...props} />;
});

//Inherits all variants from Button, but also takes Icon as input prop
const ButtonWithIcon = React.forwardRef<HTMLButtonElement, ButtonWithIconProps>(({
  className,
  variant,
  size,
  asChild = false,
  icon,
  children,
  ...props
}, ref) => {
  const Comp = asChild ? Slot : "button";
  return <Comp className={cn(buttonWithIconVariants({
    variant,
    size,
    className
  }))} ref={ref} {...props}>
        <span className="inline-flex items-center justify-center w-4 h-4 mr-2">{icon}</span>
        {children}
      </Comp>;
});
const IconButton = React.forwardRef<HTMLButtonElement, ButtonWithIconProps>(({
  className,
  variant,
  size,
  asChild = false,
  icon,
  ...props
}, ref) => {
  const Comp = asChild ? Slot : "button";
  return <Comp className={cn(buttonVariants({
    variant,
    size,
    className
  }))} ref={ref} {...props}>
        {icon}
      </Comp>;
});
Button.displayName = "Button";
ButtonWithIcon.displayName = "ButtonWithIcon";
export type ButtonVariant = VariantProps<typeof buttonVariants>['variant'];
export type ButtonSize = VariantProps<typeof buttonVariants>['size'];
export { Button, ButtonWithIcon, IconButton, buttonVariants, buttonWithIconVariants };